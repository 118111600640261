import axios from 'axios';

const apiRoute = "https://apicitas.sngcr.app";
const routes = {
  getAttendance: `${apiRoute}/get_cita`,
  acceptAttendance: `${apiRoute}/update_cita`,
};

const username = process.env.REACT_APP_USER1_NAME ?? "user1";
const password = process.env.REACT_APP_USER1_PASSWORD ?? "password1";
const basicAuth = { username: username, password: password };

export const getAttendace = async (id: string) => {
  try {
    const route = `${routes.getAttendance}?id_cita=${id}`;
    const data = await axios.get(route, { auth: basicAuth });
    return data.data;
  } catch (error) {
    console.log(error)
  }
}

export const acceptAttendance = async (id: string) => {
  const body = {
    id_cita: id,
    confirmadopor: "Confirmado por el cliente a traves del link"
  }
  const data = await axios.post(routes.acceptAttendance, body, { auth: basicAuth });
  return data;
}
