import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { getAttendace, acceptAttendance } from './services/api/api';

const App: React.FC = () => {

  console.log(process.env.USER1_NAME)
  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("id_cita");
  const [attendance, setAttendance] = useState<Record<string, any>|null>(null);
  const [attendaceAccepted, setAttendaceAccepted] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const data = await getAttendace(id ?? "");
      if(!data){
        return;
      }
      setAttendance(data?.data);
    };

    getData();
  }, []);

  if (!id || !attendance) {
    return <Container className="container mt-5">
      <h1 className="text-center">Cita no encontrada</h1>
    </Container>
  }

  if (attendaceAccepted) {
    return (
      <Container>
        <div className="row">
          <div className="col-12">
            <h1 className="text-center text-success mb-3">Cita aceptada</h1>
            <p className="text-center">La cita ha sido aceptada exitosamente</p>
            <p className="text-center">¡Gracias por utilizar nuestros servicios en Clínicas Oftalmejía!</p>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container className="container mt-5">
      <h1 className="text-center text-primary mb-3">Bienvenido a Clínicas Oftalmejía</h1>
      <h3 className="text-center mb-3"></h3>
      <p className="text-center">Le indicamos que tiene una cita el día {attendance?.fecha ?? ""} a las {attendance?.hora ?? ""} con el(la) Dr.(Dra.) {attendance?.doctor ?? ""}.</p>
      <p className="text-center">Si desea cancelar o reprogramar la cita por favor  comunicarse a la central al <a href="tel:22250375">22250375</a></p>

      {attendance.confirmado === 0 || !acceptAttendance ?
        <>
          <p className="text-center">Para confirmar la asistencia a la cita por favor presione el botón de Confirmar</p>
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={async () => {
                const data = await acceptAttendance(id);
                setAttendaceAccepted(data.status === 200);
              }}
            >Confirmar
            </Button>
          </div>
        </> : <h3 className="text-center text-success">La cita ya ha sido confirmada</h3>}

    </Container>

  );
}

export default App;